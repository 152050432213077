(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/enetpulse/assets/enetpulse.js') >= 0) return;  svs.modules.push('/components/sportinfo/enetpulse/assets/enetpulse.js');
"use strict";

const {
  useEffect,
  useMemo,
  useRef,
  useState
} = React;
const logger = svs.core.log.getLogger('sportinfo:enetpulse');
const {
  EnetpulseWidgets
} = svs.components.sportinfo.common.constants;
const EnetpulseWidget = _ref => {
  let {
    viewProps,
    externalId,
    widgetId,
    FallbackComp: DefaultFallbackComp
  } = _ref;
  const options = useMemo(() => {
    var _viewProps$options;
    return (_viewProps$options = viewProps === null || viewProps === void 0 ? void 0 : viewProps.options) !== null && _viewProps$options !== void 0 ? _viewProps$options : {};
  }, [viewProps]);
  const {
    hasMultipleWidgets
  } = viewProps;
  const containerRef = useRef(null);
  const [shouldShowFallbackComp, setShouldShowFallbackComp] = useState(false);
  useEffect(() => {
    if (!externalId || !externalId.Kambi) {
      setShouldShowFallbackComp(true);
      return;
    }
    if (!containerRef.current) {
      setShouldShowFallbackComp(true);
      return;
    }
    const script = document.createElement('script');
    script.id = 'enetpulse-widget';
    script.defer = true;
    let enetpulseIdInitialiser = 'ev';
    if (widgetId === EnetpulseWidgets.TournamentStandings) {
      enetpulseIdInitialiser = 'tsid';
    } else if (widgetId === EnetpulseWidgets.DrawStandings) {
      enetpulseIdInitialiser = 'drid';
    }
    let src = "https://widget.enetscores.com/".concat(widgetId, "/").concat(enetpulseIdInitialiser, "/").concat(externalId.Kambi);
    const optionKeys = Object.keys(options);
    if (optionKeys.length) {
      optionKeys.forEach(key => {
        src += "/".concat(key, "/").concat(options[key]);
      });
    }
    script.async = true;
    script.src = src;
    script.onerror = () => {
      logger.warn("Failed to load Enetpulse widget, showing fallback component, wID: ".concat(widgetId, ", eID: ").concat(externalId.Kambi));
      setShouldShowFallbackComp(true);
    };
    const element = containerRef.current;
    element === null || element === void 0 || element.appendChild(script);
    return () => {
      if (element && element.contains(script)) {
        element.innerHTML = '';
      }
    };
  }, [externalId, options, widgetId]);
  if (shouldShowFallbackComp && !hasMultipleWidgets) {
    return React.createElement(DefaultFallbackComp, null, " F\xF6r tillf\xE4llet g\xE5r de inte att visa n\xE5gon information. ");
  }
  return React.createElement("div", {
    id: "enetpulse-container",
    ref: containerRef
  });
};
setGlobal('svs.components.sportinfo.enetpulse.EnetpulseWidget', EnetpulseWidget);

 })(window);